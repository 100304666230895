import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const settingsSelector = ({ settings }) => settings;

export const tokenSelector = ({ token }) => token;

export const langSelector = createSelector(
  [(state) => state.settings.lang],
  (lang) => (isEmpty(lang) ? 'es' : lang)
);

export const currentDateSelector = createSelector(
  [(state) => state?.settings?.currentDate],
  (currentDate) => (isEmpty(currentDate) ? '' : currentDate)
);

export const globalSettingsSelector = createSelector(
  [
    (state) => state.settings?.clocks,
    (state) => state.settings?.expire_time,
    (state) => state.settings?.initial_order_notification,
    (state) => state.settings?.lang,
    (state) => state.settings?.sound,
    (state) => state.settings?.sound_viewer,
    (state) => state.settings?.notify_fixed,
    (state) => state.settings?.stop_order_opposite,
    (state) => state.settings?.inverse_rate_notification,
    (state) => state.settings?.notify_fixed_global,
    (state) => state.settings?.notify_fixed_viewer,
  ],
  (
    clocks,
    expireTime,
    initialOrderNotification,
    lang,
    sound,
    soundViewer,
    notifyFixed,
    stopOrderOpposite,
    inverseRateNotification,
    notifyFixedGlobal,
    notifyFixedViewer
  ) => ({
    clocks,
    expireTime,
    initialOrderNotification,
    lang,
    sound,
    soundViewer,
    notifyFixed,
    stopOrderOpposite,
    inverseRateNotification,
    notifyFixedGlobal,
    notifyFixedViewer,
  })
);

export const goldenLayoutSelector = createSelector(
  [(state) => state?.settings?.golden_layout],
  (goldenLayout) => (isEmpty(goldenLayout) ? {} : goldenLayout)
);

export const expireTimeSelector = createSelector(
  [(state) => state?.settings?.expire_time],
  (expireTime) => (isEmpty(expireTime) ? 5 : expireTime)
);
