import React from 'react';
import PropTypes from 'prop-types';

const FormInputPlusLabel = ({
  elementId,
  labelText,
  value,
  defaultValue,
  required,
  disabled,
  type,
  style,
  className,
  autoComplete,
  changeHandler,
  blurHandler,
}) => (
  <>
    <label htmlFor={elementId}>{labelText}</label>
    <input
      id={elementId}
      value={value}
      placeholder={defaultValue}
      required={required}
      disabled={disabled}
      type={type}
      style={style}
      className={className}
      autoComplete={autoComplete}
      onChange={changeHandler}
      onBlur={blurHandler}
    />
  </>
);

FormInputPlusLabel.propTypes = {
  elementId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'email',
    'search',
    'tel',
    'url',
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
};

FormInputPlusLabel.defaultProps = {
  defaultValue: undefined,
  required: false,
  disabled: false,
  type: 'text',
  style: null,
  className: null,
  autoComplete: null,
  changeHandler: null,
  blurHandler: null,
};

export default FormInputPlusLabel;
