import { AUTH_SOCKET } from '../actions/action_types';

export default function authReducer(state = { authenticated: false }, action) {
  switch (action.type) {
    case AUTH_SOCKET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
