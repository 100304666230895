/* global ENV */
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy,
  useCallback,
} from 'react';
import { string, bool, shape, func, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from '../components/common/Notifications';
import Loading from '../components/common/loading';
import logger from '../utils/logger';
import returnStatusUtil from '../utils/status';

import { orders as orderLang } from '../configs/language';
import {
  userPermissions as userPermissionsAction,
  reduxSetMatcherSystemStatus as reduxSetMatcherSystemStatusAction,
  reduxSetSessionStatus as reduxSetSessionStatusAction,
  reduxSetUserSettings as reduxSetUserSettingsAction,
  reduxSetPermissions as reduxSetPermissionsAction,
  reduxFollowClients as reduxFollowClientsAction,
  reduxSetSessionServiceStartup as reduxSetSessionServiceStartupAction,
  reduxBookUpdate as reduxBookUpdateAction,
  reduxUpdateAssetRfq as reduxUpdateAssetRfqAction,
  reduxRfqUpdate as reduxRfqUpdateAction,
  reduxSetHistoricalOrders as reduxSetHistoricalOrdersAction,
  reduxFollowOrder as reduxFollowOrderAction,
  reduxInstrumentsCreditCheck as reduxInstrumentsCreditCheckAction,
  reduxDisableUI as reduxDisableUIAction,
  reduxPostAsset as reduxPostAssetAction,
  reduxIncomingOrder as reduxIncomingOrderAction,
  reduxStpUpdate as reduxStpUpdateAction,
  reduxUpdateAsset as reduxUpdateAssetAction,
  reduxCompletedOrder as reduxCompletedOrderAction,
  reduxSetLoadInstrumentPricingReady as reduxSetLoadInstrumentPricingReadyAction,
  reduxWSLatency as reduxWSLatencyAction,
  reduxSetCurrentDate as reduxSetCurrentDateAction,
  reduxOrderUpdate as reduxOrderUpdateAction,
  reduxSetSystemStatus as reduxSetSystemStatusAction,
  reduxTobUpdate as reduxTobUpdateAction,
  reduxCreditCheck as reduxCreditCheckAction,
  reduxWebsocketMonitor as reduxWebsocketMonitorAction,
  addRfqSubscription as addRfqSubscriptionAction,
  updateRfqSubscription as updateRfqSubscriptionAction,
} from '../actions';

import { audioSell, audioSellSpanish } from '../utils/sounds';
import { userDetailsSelector } from '../selectors/userDetailsSelector';
import { globalSettingsSelector } from '../selectors/settingsSelector';
import { allInstrumentsSelector } from '../selectors/instrumentsSelector';

import {
  getPartialOrders,
  getTodayOrders,
  getViewUsers,
} from '../callers/getOrdersCall';

import {
  ViewerNotification,
  viewerNotificationFields,
} from '../components/notifications/viewerNotification';
import {
  createUserNotification,
  userNotificationMessage,
  userNotificationType,
  validateSessionTime,
  validateToday,
  StpNotificationText,
  ViewerNotificationText,
} from '../components/notifications/userNotification';

import { useWebsocket } from '../hooks/useWebsocket';
import { httpOrigin } from '../configs/apiOrigin';
import setActiveStatusUser from '../callers/activeUser';
import probabilityCheck from '../utils/probabilityCheck';
import appEnvCfg from '../configs/app';
import popoutComponentName from '../utils/popoutComponentName';

const GoldenLayoutWrapper = lazy(() =>
  import('../components/layout/goldenLayout')
);

const PopoutHelper = lazy(() => import('../components/layout/PopoutHelper'));

window.interactions = [];

const viewerNotifiedOrders = {};
const notifiedOrders = {};

const Dashboard = ({
  popout,
  subscriptions,
  assets,
  globalSettings,
  ordersRedux,
  stpRows,
  widgetsConfiguration,
  userDetails,
  followClients,
  userPermissions,
  reduxSetMatcherSystemStatus,
  reduxSetSessionStatus,
  reduxSetUserSettings,
  reduxSetPermissions,
  reduxFollowClients,
  reduxSetSessionServiceStartup,
  reduxBookUpdate,
  reduxUpdateAssetRfq,
  reduxRfqUpdate,
  reduxSetHistoricalOrders,
  reduxFollowOrder,
  reduxInstrumentsCreditCheck,
  reduxDisableUI,
  reduxPostAsset,
  reduxIncomingOrder,
  reduxStpUpdate,
  reduxUpdateAsset,
  reduxCompletedOrder,
  reduxSetLoadInstrumentPricingReady,
  reduxWSLatency,
  reduxSetCurrentDate,
  reduxOrderUpdate,
  reduxSetSystemStatus,
  reduxTobUpdate,
  reduxCreditCheck,
  reduxWebsocketMonitor,
  reduxAddRfqSubscription,
  reduxUpdateRfqSubscription,
}) => {
  const [initialDataReady, setInitialDataReady] = useState(false);
  const websocket = useWebsocket({
    reduxWebsocketMonitor,
    ready: initialDataReady,
  });

  const [glow, setGlow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [batchOrders, setBatchOrders] = useState([]);
  const [stpOrder, setStpOrder] = useState({});
  const subRef = useRef();
  const assetsRef = useRef();
  const globalSettingsRef = useRef();
  const globalWidgetsConfigRef = useRef();
  const globalStpRowsRef = useRef();
  const pingHeartbeat = useRef();
  const username = useRef();
  const isViewer = useRef();
  const latency = useRef(Date.now());
  const skippedHearbeats = useRef(0);
  const popoutRef = useRef(popout);
  const ordersTimeout = useRef();
  const batchOrdersTimeout = useRef();
  const location = useLocation();

  const completedOrderTimeout = useRef();

  const fetchUsersSettings = async () => {
    const origin = httpOrigin(`/trader-rest-service/v1/user-settings`);

    try {
      const response = await fetch(origin, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      // eslint-disable-next-line no-restricted-syntax
      for (const [domain, setting] of Object.entries(data)) {
        reduxSetUserSettings(setting, domain);
      }
    } catch (err) {
      if (ENV === 'development') console.error(err);
    }
  };

  const fetchEnabledWidgets = async () => {
    const apiUrlOrigin = httpOrigin(`/trader-rest-service/v1/user-widgets`);

    try {
      const response = await fetch(apiUrlOrigin, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      reduxSetPermissions(data);
    } catch (err) {
      if (ENV === 'development') console.error(err);
    }
  };

  const fetchUserInformation = async () => {
    const apiUrlOrigin = httpOrigin(`/trader-rest-service/v1/user-information`);

    try {
      const response = await fetch(apiUrlOrigin, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      reduxSetUserSettings(data, 'global_settings');
    } catch (err) {
      if (ENV === 'development') console.error(err);
    }
  };

  const fetchViewers = async () => {
    const apiUrlOrigin = httpOrigin(`/trader-rest-service/v1/getViewers`);

    try {
      const response = await fetch(apiUrlOrigin, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      reduxFollowClients(data);
    } catch (err) {
      if (ENV === 'development') console.error(err);
    }
  };

  /**
   * Monitor if connection is alive with Web Server
   *
   * 5 second interval
   *
   * 504 = UI <--> Web Server (transport: socket.io) 20 missed = 100 secs no heartbeat
   */
  const startPing = () => {
    clearInterval(pingHeartbeat.current);
    skippedHearbeats.current = 0;
    latency.current = Date.now();

    pingHeartbeat.current = setInterval(() => {
      if (!latency.current) {
        latency.current = Date.now();
      }
      skippedHearbeats.current += 1;

      if (skippedHearbeats.current > 20) {
        reduxSetSystemStatus({ wsStatus: false });
      }

      latency.current = Date.now();

      websocket.emit('msg', {
        msgType: 'ping',
        data: { platform: window.APP_NAME },
      });
    }, 5000);
  };

  useEffect(() => {
    // start new session
    sessionStorage.removeItem('fetchPartialOrders');
    sessionStorage.removeItem('fetchYesterdayStartAt');
    sessionStorage.removeItem('clickTabInBlotter');

    const fetchInitialData = async () => {
      await Promise.all([
        fetchUsersSettings(),
        fetchEnabledWidgets(),
        fetchUserInformation(),
        fetchViewers(),
      ]);

      setInitialDataReady(true);
    };

    if (popoutComponentName()) {
      setInitialDataReady(true);
    } else {
      fetchInitialData();
    }

    reduxSetCurrentDate();
  }, []);

  useEffect(() => {
    if (initialDataReady) {
      websocket.on('load-subscription', loadSubscription);
      websocket.on(
        'load-instrument-pricing-ready',
        loadCurrentPricingSubscription
      );
      websocket.on('set-permission', userPermissions);
      websocket.on('matcher-system-status', reduxSetMatcherSystemStatus);
      websocket.on('session-status', reduxSetSessionStatus);
      websocket.on('session-service-startup', reduxSetSessionServiceStartup);
      websocket.on('book-update', reduxBookUpdate);
      websocket.on('update-subscription', reduxUpdateAssetRfq);
      websocket.on('rfq-update', reduxRfqUpdate);
      websocket.on('rfq-order-new', orderUpdate);
      websocket.on('order-update', orderUpdate);
      websocket.on('order-update-batch', orderBatchUpdate);
      websocket.on('historical-orders-dates', reduxSetHistoricalOrders);
      websocket.on('logout', logout);
      websocket.on('tob-update', tobUpdate);
      websocket.on('follow-order', reduxFollowOrder);
      websocket.on('setting', settingUpdate);
      websocket.on('credit-check-update', creditCheck);
      websocket.on('rfq-subscribe', reduxAddRfqSubscription);
      websocket.on('rfq-subscribe-update', reduxUpdateRfqSubscription);
      websocket.on('rfq-unsubscribe', reduxUpdateRfqSubscription);
      websocket.on('instruments-credit-check', reduxInstrumentsCreditCheck);
      websocket.on('stp-update', stpUpdate);
      websocket.on('reload-ui', reloadUI);
      websocket.on('disable-ui', reduxDisableUI);
      websocket.on('pong', pongCheck);
      websocket.on('auth-result', socketConnected);
    }
  }, [initialDataReady]);

  useEffect(() => {
    subRef.current = subscriptions;
  }, [subscriptions]);

  useEffect(() => {
    assetsRef.current = assets;
  }, [assets]);

  useEffect(() => {
    popoutRef.current = popout;
  }, [popout]);

  useEffect(() => {
    globalWidgetsConfigRef.current = widgetsConfiguration;
  }, [widgetsConfiguration]);

  useEffect(() => {
    globalStpRowsRef.current = stpRows;
  }, [stpRows]);

  useEffect(() => {
    username.current = userDetails.username;
  }, [userDetails.username]);

  useEffect(() => {
    globalSettingsRef.current = globalSettings;
  }, [globalSettings]);

  useEffect(() => {
    clearTimeout(ordersTimeout.current);
    if (orders.length) {
      ordersTimeout.current = setTimeout(() => {
        const realOrders = [...orders];
        const ol = realOrders.length;
        reduxIncomingOrder(realOrders);
        setOrders((c) => c.slice(ol));
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    clearTimeout(batchOrdersTimeout.current);
    if (batchOrders.length) {
      batchOrdersTimeout.current = setTimeout(() => {
        reduxIncomingOrder([...batchOrders]);
      }, 250);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchOrders]);

  useEffect(() => {
    globalSettingsRef.current = globalSettings;
  }, [globalSettings]);

  useEffect(() => {
    const {
      organizations = [],
      users = [],
      super_orders_viewer: superOrdersViewer = false,
      super_shadow_viewer: superShadowViewer = false,
    } = followClients || {};
    const viewerOrg = organizations?.length || 0;
    const viewerUsu = users?.length || 0;

    isViewer.current =
      viewerOrg > 0 || viewerUsu > 0 || superOrdersViewer || superShadowViewer;
  }, [followClients]);

  useEffect(() => {
    const currGlobalSettings = globalSettingsRef.current;
    if (Object.keys(stpOrder).length && !popoutRef.current) {
      const dataParent = ordersRedux[stpOrder.client_order_id];

      if (dataParent) {
        const stateOrderStop = 'FILLED';

        if (dataParent.username !== username.current) {
          if (currGlobalSettings.notifyFixedViewer) {
            const title = dataParent.organization_name;
            const hide = !currGlobalSettings.notifyFixed;
            const text = StpNotificationText({
              dataParent,
              order: stpOrder,
              stateOrderStop,
              lang: currGlobalSettings.lang,
              orderLang,
              isCurrentUser: false,
            });

            const fields = viewerNotificationFields({ title, text, hide });
            const opts = {
              soundViewer: currGlobalSettings.soundViewer,
            };
            ViewerNotification(fields, opts);
          }
        } else {
          const text = StpNotificationText({
            dataParent,
            order: stpOrder,
            stateOrderStop,
            lang: currGlobalSettings.lang,
            orderLang,
            isCurrentUser: true,
          });

          createNotification(text, stateOrderStop);
        }

        if (currGlobalSettings.sound) {
          if (currGlobalSettings.lang === 'es') {
            audioSellSpanish.play().catch((err) => {
              console.warn(err);
            });
          } else {
            audioSell.play().catch((err) => {
              console.warn(err);
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stpOrder]);

  const stpUpdate = useCallback(
    (data) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty('row_bd')) setStpOrder(data);
      reduxStpUpdate(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const socketConnected = () => {
    if (ENV === 'development') {
      console.log('socket has been authorized');
    }
    const token = `${sessionStorage.getItem('token')}`;

    const popoutCompName = popoutComponentName();

    if (!popoutCompName) {
      getTodayOrders({
        ...userDetails,
        token,
      }).then((todayOrders) => {
        orderBatchUpdate(todayOrders);
      });

      getViewUsers({
        ...userDetails,
        viewers: followClients,
        token,
      }).then((viewerOrders) => {
        orderBatchUpdate(viewerOrders);
      });

      // only run if show_partials
      if (widgetsConfiguration?.blotter_widget?.show_partials) {
        getPartialOrders({
          ...userDetails,
          token,
          viewers: followClients,
        }).then((data) => {
          if (Array.isArray(data)) {
            data.forEach((stp) => stpUpdate(stp));
          }
        });
      }
    }

    reduxSetSystemStatus({ wsStatus: true, webSocketStatus: true }); // set do default values all
    startPing();

    websocket.emit('msg', {
      msgType: 'load-initial-instruments',
      data: {
        token: `${sessionStorage.getItem('token')}`,
      },
    });
  };

  const pongCheck = useCallback(() => {
    skippedHearbeats.current = 0;
    const lt = Date.now() - latency.current;
    latency.current = 0;
    reduxSetSystemStatus({ wsStatus: true });
    reduxWSLatency(lt);

    if (probabilityCheck(0.85)) {
      setActiveStatusUser({
        ping: lt,
        platform: window.APP_NAME,
        token: sessionStorage.getItem('token'),
      }).catch(() => {});
    }

    // set current date in reducer to update resetTime in pnl
    reduxSetCurrentDate();

    if (lt > appEnvCfg().WEBSOCKET_LATENCY_THRESHOLD_MAJOR) {
      // eslint-disable-next-line no-console
      console.log(
        `[--LATENCY--] websocket ${lt} over threshold ${
          appEnvCfg().WEBSOCKET_LATENCY_THRESHOLD_MAJOR
        }`
      );
      logger.logWarning('latency websocket threshold breached', {
        isLogIfHidden: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSubscription = (data) => {
    const newAssets = assetsRef.current;
    const newSubscriptions = subRef.current;

    if (
      !newAssets.length ||
      newAssets.findIndex((a) => a.instrument_id === data[0].instrument_id) ===
        -1
    )
      reduxPostAsset(data);
    else if (data.length === 1) reduxUpdateAsset(data[0]);

    Object.keys(newSubscriptions).map((id) =>
      Object.keys(newSubscriptions[id]).map((algo) =>
        websocket.emit('msg', {
          msgType: 'start-instrument-subscription',
          data: { id: Number(id), algo },
        })
      )
    );
  };

  const loadCurrentPricingSubscription = (data) => {
    reduxSetLoadInstrumentPricingReady(data);
    const newSubscriptions = subRef.current;

    if (ENV === 'development') {
      console.log(
        '[DASHBOARD] Loading to webserver the current subscription data',
        newSubscriptions
      );
    }

    Object.keys(newSubscriptions).map((id) =>
      Object.keys(newSubscriptions[id]).map((algo) =>
        websocket.emit('msg', {
          msgType: 'start-instrument-subscription',
          data: { id: Number(id), algo },
        })
      )
    );
  };
  /**
   * Creates a user notification of an order just when needed
   * @param {*} data
   * @returns
   */
  const checkForUserNotification = useCallback((data) => {
    const currGlobalSettings = globalSettingsRef.current;
    const { partial_widget: partialWidget } = globalWidgetsConfigRef.current;
    const globalStpRows = globalStpRowsRef.current;

    /** Conditions to not create notifications */
    if (
      partialWidget &&
      partialWidget.enabled &&
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty('row_bd') &&
      globalStpRows[data.client_order_id]
    ) {
      return;
    }

    // Skip notifications ******************************/
    if (popoutRef.current) return;
    if (data.replay && !data.re_client_org_name) return;
    if (!validateToday(Number(data.trade_date))) return;
    if (!validateSessionTime(data.timestamp)) return;
    /** ********************************************** */

    // eslint-disable-next-line spaced-comment
    /******************************************/

    const status = returnStatusUtil(data);
    const msg = userNotificationMessage(
      data,
      status,
      orderLang,
      currGlobalSettings.lang
    );

    const notificationType = userNotificationType(
      status,
      Number(data.qty_filled)
    );
    const sound =
      (notificationType === 'partial' || notificationType === 'complete') &&
      currGlobalSettings.sound;

    const opts = {
      showNotification: currGlobalSettings.notifyFixedGlobal,
      sound,
      side: data.side,
      lang: currGlobalSettings.lang,
    };

    if (
      notificationType !== 'cancel' &&
      window.APP_NAME.includes('edgefx') &&
      !notifiedOrders[data.client_order_id]
    ) {
      setGlow(true);
    }

    // New Orders
    if (status === 'NEW') {
      opts.showNotification = currGlobalSettings.initialOrderNotification;
      createUserNotification(msg, 'active', opts);
      return;
    }

    if (
      data.re_client_org_name &&
      notifiedOrders[data.client_order_id] === undefined
    ) {
      notifiedOrders[data.client_order_id] = 'pending';
      return;
    }

    // Finished orders
    if (
      (Number(data.qty_open) === 0 && !notifiedOrders[data.client_order_id]) ||
      notifiedOrders[data.client_order_id] === 'pending'
    ) {
      notifiedOrders[data.client_order_id] = true;
      createUserNotification(msg, notificationType, opts);
      return;
    }

    // Partial Orders
    if (notificationType === 'partial') {
      createUserNotification(msg, notificationType, opts);
    }
  }, []);

  /**
   * Creates a viewer notification of an order when
   * the user has viewer permissions only
   * @param {*} data
   */
  const checkForViewerNotification = useCallback((data) => {
    const {
      client_order_id: clientOrderId,
      organization_name: organizationName,
      trade_date: tradeDate,
      status,
    } = data;
    const currGlobalSettings = globalSettingsRef.current;
    const identifier = `${clientOrderId}`;
    const orderExist = !!viewerNotifiedOrders[identifier];

    if (
      status === 'FILLED' &&
      validateToday(Number(tradeDate)) &&
      validateSessionTime(data.timestamp) &&
      !orderExist
    ) {
      const opts = {
        soundViewer: currGlobalSettings.soundViewer,
        showNotification: currGlobalSettings.notifyFixedViewer,
      };
      const title = organizationName;
      const text = ViewerNotificationText({
        lang: currGlobalSettings.lang,
        data,
        orderLang,
      });
      const hide = !currGlobalSettings.notifyFixed;

      const fields = viewerNotificationFields({ title, text, hide });
      ViewerNotification(fields, opts);
      viewerNotifiedOrders[identifier] = true;
    }
  }, []);

  const checkOrderCompleted = useCallback((data) => {
    if (!data.replay && popoutRef.current) {
      const status = returnStatusUtil(data);
      if (
        status === 'PARTIALLY_FILLED' ||
        (status === 'CANCELED' && Number(data.qty_filled) > 0) ||
        status === 'FILLED'
      ) {
        clearTimeout(completedOrderTimeout.current);
        reduxCompletedOrder(true);
        completedOrderTimeout.current = setTimeout(() => {
          reduxCompletedOrder(false);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderUpdate = useCallback(
    (data) => {
      if (data.order_type === 'PEGG' || data.order_type === 'PEGGTAKETOP') {
        reduxOrderUpdate(data);
      }

      setOrders((old) => [...old, data]);
      checkOrderCompleted(data);

      if (
        isViewer.current &&
        data.username !== username.current &&
        !data.cover_organization_name &&
        !data.re_client_org_name
      ) {
        checkForViewerNotification(data);
      } else {
        checkForUserNotification(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkOrderCompleted, checkForViewerNotification, checkForUserNotification]
  );

  const orderBatchUpdate = useCallback((batch) => {
    if (Array.isArray(batch)) {
      const notNullOrders = batch.filter((o) => o !== null);
      setBatchOrders((old) => [...old, ...notNullOrders]);
    }
  }, []);

  const createNotification = useCallback((data, type) => {
    const currGlobalSettings = globalSettingsRef.current;
    if (!currGlobalSettings.notifyFixedGlobal) return null;

    switch (type) {
      case 'cancel':
        return NotificationManager.error(data, null, 10000);
      case 'partial':
        return NotificationManager.warning(data, null, 10000);
      case 'active':
        return NotificationManager.info(data, null, 10000);
      case 'no_notification': // this should be the default.
        return null;
      default:
        return NotificationManager.success(data, null, 10000);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('logout');
    window.location.reload();
  }, []);

  const reloadUI = useCallback(() => {
    window.location.reload();
  }, []);

  const tobUpdate = useCallback((data) => {
    reduxTobUpdate({
      i: data.TOB[0], // instrument_id
      m: data.TOB[1], // volume in millions
      b: data.TOB[2], // bid
      a: data.TOB[3], // ask
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingUpdate = useCallback((data) => {
    const domains = Object.keys(data);
    domains.forEach((domain) => {
      if (domain && domain !== 'instruments_widget')
        // Ignore the instruments_widget setting update, since it is handled by the drag n drop event
        reduxSetUserSettings(data[domain], domain);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creditCheck = useCallback((data) => {
    reduxCreditCheck(data?.data || data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialDataReady || !assets.length) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      {!location.pathname.includes('widget') ? (
        <GoldenLayoutWrapper
          setGlowing={setGlow}
          glow={glow}
          socket={websocket}
          orderBatchUpdate={orderBatchUpdate}
          stpUpdate={stpUpdate}
        />
      ) : (
        <div id="modal-root">
          <PopoutHelper
            socket={websocket}
            orderBatchUpdate={orderBatchUpdate}
            stpUpdate={stpUpdate}
          />
        </div>
      )}
    </Suspense>
  );
};

Dashboard.propTypes = {
  popout: bool,
  subscriptions: shape(),
  assets: arrayOf(shape()),
  globalSettings: shape({
    initialOrderNotification: bool,
    lang: string,
    sound: bool,
    soundViewer: bool,
    notifyFixed: bool,
    notifyFixedGlobal: bool,
    notifyFixedViewer: bool,
  }),
  ordersRedux: shape(),
  widgetsConfiguration: shape({
    orders_settings: shape({
      viewer_covers: bool,
    }),
    partial_widget: shape({
      enabled: bool,
    }),
  }),
  userDetails: shape(),
  stpRows: arrayOf(shape()),
  followClients: shape(),
  userPermissions: func.isRequired,
  reduxSetMatcherSystemStatus: func.isRequired,
  reduxSetSessionStatus: func.isRequired,
  reduxSetUserSettings: func.isRequired,
  reduxSetPermissions: func.isRequired,
  reduxFollowClients: func.isRequired,
  reduxSetSessionServiceStartup: func.isRequired,
  reduxBookUpdate: func.isRequired,
  reduxUpdateAssetRfq: func.isRequired,
  reduxRfqUpdate: func.isRequired,
  reduxSetHistoricalOrders: func.isRequired,
  reduxFollowOrder: func.isRequired,
  reduxInstrumentsCreditCheck: func.isRequired,
  reduxDisableUI: func.isRequired,
  reduxPostAsset: func.isRequired,
  reduxIncomingOrder: func.isRequired,
  reduxStpUpdate: func.isRequired,
  reduxUpdateAsset: func.isRequired,
  reduxCompletedOrder: func.isRequired,
  reduxSetLoadInstrumentPricingReady: func.isRequired,
  reduxWSLatency: func.isRequired,
  reduxSetCurrentDate: func.isRequired,
  reduxOrderUpdate: func.isRequired,
  reduxSetSystemStatus: func.isRequired,
  reduxTobUpdate: func.isRequired,
  reduxCreditCheck: func.isRequired,
  reduxWebsocketMonitor: func.isRequired,
  reduxAddRfqSubscription: func.isRequired,
  reduxUpdateRfqSubscription: func.isRequired,
  // reduxBrowserPerfCpu: func.isRequired,
  // reduxBrowserPerfRam: func.isRequired,
};

Dashboard.defaultProps = {
  popout: false,
  subscriptions: {},
  assets: [],
  globalSettings: {
    initialOrderNotification: false,
    lang: 'es',
    sound: false,
    soundViewer: false,
    notifyFixed: false,
    notifyFixedGlobal: false,
    notifyFixedViewer: false,
  },
  ordersRedux: {},
  widgetsConfiguration: {
    orders_settings: {
      viewer_covers: false,
    },
    partial_widget: {
      enabled: false,
    },
    blotter_widget: {
      show_partials: false,
    },
  },
  userDetails: {},
  stpRows: [],
  followClients: {},
};

function mapStateToProps(state) {
  const {
    userId,
    organizationId,
    username,
    rateEngineAdmin,
    shadowAdmin,
    isViewer,
  } = userDetailsSelector(state);

  const {
    lang,
    initialOrderNotification,
    notifyFixedViewer,
    notifyFixed,
    sound,
    soundViewer,
    notifyFixedGlobal,
  } = globalSettingsSelector(state);

  return {
    globalSettings: {
      lang,
      initialOrderNotification,
      notifyFixedViewer,
      notifyFixed,
      sound,
      soundViewer,
      notifyFixedGlobal,
    },
    assets: allInstrumentsSelector(state),
    subscriptions: state.subscriptions,
    popout: state.popout,
    ordersRedux: state.orders.followClient
      ? state.orders.followOrders
      : state.orders.orders,
    widgetsConfiguration: state.widgets_configuration,
    stpRows: state.stp,
    followClients: state.followClients,
    userDetails: {
      userId,
      organizationId,
      username,
      rateEngineAdmin,
      shadowAdmin,
      isViewer,
    },
  };
}

export default connect(mapStateToProps, {
  userPermissions: userPermissionsAction,
  reduxSetMatcherSystemStatus: reduxSetMatcherSystemStatusAction,
  reduxSetSessionStatus: reduxSetSessionStatusAction,
  reduxSetUserSettings: reduxSetUserSettingsAction,
  reduxSetPermissions: reduxSetPermissionsAction,
  reduxFollowClients: reduxFollowClientsAction,
  reduxSetSessionServiceStartup: reduxSetSessionServiceStartupAction,
  reduxBookUpdate: reduxBookUpdateAction,
  reduxUpdateAssetRfq: reduxUpdateAssetRfqAction,
  reduxRfqUpdate: reduxRfqUpdateAction,
  reduxSetHistoricalOrders: reduxSetHistoricalOrdersAction,
  reduxFollowOrder: reduxFollowOrderAction,
  reduxInstrumentsCreditCheck: reduxInstrumentsCreditCheckAction,
  reduxDisableUI: reduxDisableUIAction,
  reduxPostAsset: reduxPostAssetAction,
  reduxIncomingOrder: reduxIncomingOrderAction,
  reduxStpUpdate: reduxStpUpdateAction,
  reduxUpdateAsset: reduxUpdateAssetAction,
  reduxCompletedOrder: reduxCompletedOrderAction,
  reduxSetLoadInstrumentPricingReady: reduxSetLoadInstrumentPricingReadyAction,
  reduxWSLatency: reduxWSLatencyAction,
  reduxSetCurrentDate: reduxSetCurrentDateAction,
  reduxOrderUpdate: reduxOrderUpdateAction,
  reduxSetSystemStatus: reduxSetSystemStatusAction,
  reduxTobUpdate: reduxTobUpdateAction,
  reduxCreditCheck: reduxCreditCheckAction,
  reduxWebsocketMonitor: reduxWebsocketMonitorAction,
  reduxAddRfqSubscription: addRfqSubscriptionAction,
  reduxUpdateRfqSubscription: updateRfqSubscriptionAction,
})(React.memo(Dashboard));
