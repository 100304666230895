import { USER_PERMISSIONS } from '../actions/action_types';

export default function userPermissionsReducer(state = {}, action) {
  switch (action.type) {
    case USER_PERMISSIONS:
      return { ...state, permissions: action.payload.permissions };
    default:
      return state;
  }
}
