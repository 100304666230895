import { createSelector } from 'reselect';

const getUserDetails = (state) => state.userDetails;

export const usernameSelector = createSelector(
  [getUserDetails],
  ({ username }) => username
);

export const userDetailsSelector = createSelector(
  [getUserDetails],
  ({
    userId,
    organizationId,
    username,
    organization,
    rateEngineEnabled: rateEngineAdmin,
    shadowAdmin,
    isViewer,
  }) => ({
    userId,
    organizationId,
    username,
    organization,
    rateEngineAdmin,
    shadowAdmin,
    isViewer,
  })
);
