import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import dateTime from './utils/date-time';
import getStore from './reduxStore';
import routes from './routes';
import logger from './utils/logger';
import { getNameAbsTheme } from './utils/themeConfig';
import { getPalette, getTheme } from './components/theme';
import { getHost } from './utils/platform';
import appEnvCfg from './configs/app';

const wlByHostname = getHost();
window.APP_NAME = wlByHostname.APP_NAME;
window.APP_TITLE = wlByHostname.APP_TITLE;
const favicon = wlByHostname.hasFavicon ? wlByHostname.APP_NAME : 'edgewater';
const APP_NAME_ABSOLUTE = getNameAbsTheme(window.APP_NAME);

const store = getStore();
export default store;

logger.setStore(store);

if (
  [
    'edgefx',
    'edgefxdemo',
    'edgefxprime',
    'crypto',
    'gfifx',
    'edgefxasia',
    'trustone',
    'bancolombia',
  ].includes(APP_NAME_ABSOLUTE)
) {
  import('./assets/styles/main-blue.scss');
} else if (APP_NAME_ABSOLUTE === 'tpifx') {
  import('./assets/styles/main-blue-header-white.scss');
} else if (APP_NAME_ABSOLUTE === '24exchangefx') {
  import('./assets/styles/main-gray.scss');
} else if (
  ['makor', 'msri', 'lloydsfx', 'nologo'].includes(APP_NAME_ABSOLUTE)
) {
  import('./assets/styles/main-white.scss');
} else if (['edgefxlight', 'shinhan'].includes(APP_NAME_ABSOLUTE)) {
  import('./assets/styles/main-blue-light.scss');
} else if (APP_NAME_ABSOLUTE === 'aliorfx') {
  import('./assets/styles/main.scss');
} else if (APP_NAME_ABSOLUTE === 'mfdf') {
  import('./assets/styles/main-mifel.scss');
} else if (APP_NAME_ABSOLUTE === 'xp') {
  import('./assets/styles/main-black-header-white.scss');
} else {
  import('./assets/styles/main.scss');
}

Sentry.init({
  dsn: 'https://c1bfaf92cce94c4cbe5e2037a7ddd7c6@o510656.ingest.sentry.io/4505470742495232',
  environment: window.ENV,
  release: window.API_VERSION,
  autoSessionTracking: false,
  integrations: [
    new Sentry.BrowserTracing(),
    // new Sentry.Replay({ maskAllText: false, maskAllInputs: false }),
  ],
  beforeSend(event) {
    if (window.ENV !== 'production' && !appEnvCfg().IS_ENABLE_SENTRY_LOG) {
      return null;
    }
    const isOutOfHours = dateTime.getIsOutOfHours(new Date());
    if (isOutOfHours) return null;
    return event;
  },
  // Sampling Error Events
  // Info: https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-error-events
  // 2021/03/24: disable 504 red banner and increase month quota to 355K
  sampleRate: 1.0,
  // Performance Monitoring:
  // Reduce for production to avoid consuming quota too quickly
  tracesSampleRate: 1.0,
  // // If the entire session is not sampled, use the below sample rate to sample
  // // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const theme = useMemo(() => {
    const palette = getPalette();
    return getTheme(palette);
  }, []);

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>{routes}</MuiThemeProvider>
    </Provider>
  );
};

ReactDOM.render(
  <>
    <Helmet>
      <title>{wlByHostname.APP_TITLE}</title>
      <link
        rel="icon"
        href={`/static/favicons/${favicon}.ico`}
        type="image/x-icon"
      />
    </Helmet>
    <App />
  </>,
  document.getElementById('root')
);
