import { reduxSetToken } from '../../../actions';
import { httpOrigin } from '../../../configs/apiOrigin';
import logger from '../../../utils/logger';

interface AuthnResponse {
  user: {
    token: string;
    username: string;
    id_companies: number;
    company_name: string;
  };
}

function repository() {
  const prepForStorage = (val: string | number) => {
    if (typeof val !== 'string') return JSON.stringify(val);
    return val;
  };

  const storeLocalSession = (authnResponse: AuthnResponse) => {
    const { user } = authnResponse;
    const {
      token,
      username,
      id_companies: idCompany,
      company_name: companyName,
    } = user;

    window.sessionStorage.setItem('token', prepForStorage(token));
    window.sessionStorage.setItem('global_username', prepForStorage(username));
    window.sessionStorage.setItem('global_org_id', prepForStorage(idCompany));
    window.sessionStorage.setItem(
      'global_organization',
      prepForStorage(companyName)
    );
  };

  const storeTokenInState = (token: string) => {
    reduxSetToken(prepForStorage(token));
  };

  const origin = httpOrigin('/trader-rest-service/v1/authenticate');

  const apiAuthnRequest = ({ username = '', password = '' } = {}) =>
    fetch(origin, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status !== 200) {
          logger.logWarning('Authentication: NOT authorized');
          throw new Error('Unauthorized response: API');
        }
        return response.json();
      })
      .then((resJson) => {
        const { token } = resJson;
        if (token == null) {
          logger.logWarning(
            'Authentication: token not returned from API authn endpoint'
          );
          throw new Error('Unexpected response: API did not return a token');
        }

        storeLocalSession(resJson);
        storeTokenInState(token);
        sessionStorage.setItem('reloadAfterLogin', 'true');
      })
      .catch((err) => {
        logger.logCriticalError(err);
        throw err;
      });

  return Object.freeze({
    apiAuthnRequest,
  });
}

export default repository;
