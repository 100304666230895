import { createSelector } from 'reselect';

const getInstruments = (state) => state.assets;

// https://reselect.js.org/usage/best-practices/
// https://reselect.js.org/usage/handling-empty-array-results

export const allInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets) => (assets.length ? assets : [])
);

export const clPrInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (inst) => ['fx', 'ndf'].includes(inst.type) && inst.tenor === 'rfq'
    )
);

export const tradingInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (instrument) => instrument.tenor !== 'rfq' && instrument.tenor !== 'swap'
    )
);

const instrumentsTypeSelector = (_, tenor) => (tenor === 'sp' ? 'rfq' : tenor);

export const rfqInstrumentsSelector = createSelector(
  [getInstruments, instrumentsTypeSelector],
  (instruments, tenor) => {
    const parsedPairs = {};

    instruments.forEach((i) => {
      if (!parsedPairs[i.symbol]) {
        parsedPairs[i.symbol] = [i.type, i.tenor];
      } else {
        parsedPairs[i.symbol] = parsedPairs[i.symbol].concat([i.type, i.tenor]);
      }
    });

    return instruments.filter(({ symbol, tenor: iTenor, type }) => {
      switch (tenor) {
        case 'ndf':
          return (
            parsedPairs[symbol].includes('rfq') &&
            parsedPairs[symbol].includes('ndf') &&
            iTenor === 'rfq' &&
            type === 'ndf'
          );
        case 'nds':
          return (
            parsedPairs[symbol].includes('swap') &&
            parsedPairs[symbol].includes('ndf') &&
            iTenor === 'swap' &&
            type === 'ndf'
          );
        case 'swap':
          return (
            parsedPairs[symbol].includes('swap') &&
            !parsedPairs[symbol].includes('ndf') &&
            iTenor === 'swap' &&
            type === 'fx'
          );
        default:
          return (
            parsedPairs[symbol].includes(tenor) &&
            !parsedPairs[symbol].includes('ndf') &&
            iTenor === 'rfq' &&
            type === 'fx'
          );
      }
    });
  }
);

const getInstrumentId = (_, id) => id;

export const maxAmountOrderByInstrumentSelector = createSelector(
  [getInstruments, getInstrumentId],
  (instruments, id) => {
    if (id) {
      const { max_amount_per_order: maxAmount } = Object.values(
        instruments
      ).find(
        ({ instrument_id: instrumentId }) => Number(id) === Number(instrumentId)
      );

      return maxAmount;
    }
    return '';
  }
);
