/* global ENV */
import {
  ADD_SUB,
  REMOVE_SUB,
  UPDATE_SUB_ALGO,
  ADD_MULTIPLE_SUBS,
} from '../actions/action_types';

export default function subscriptionsReducer(state = {}, action) {
  let id = '';
  let algo = '';
  let widget = '';

  switch (action.type) {
    case REMOVE_SUB: {
      id = action.payload.id;
      algo = action.payload.algo;
      widget = action.payload.widget;
      if (!state[id] || !state[id][algo]) return state;
      let updatedList = removeWidgetFromList(state[id][algo], widget);
      if (updatedList.length === 0) {
        stopSubscription(id, algo);
        updatedList = undefined;
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          [algo]: updatedList,
        },
      };
    }
    case ADD_SUB:
      id = action.payload.id;
      algo = action.payload.algo;
      widget = action.payload.id;
      if (!state[id] || !state[id][algo] || state[id][algo].length === 0)
        startSubscription(id, algo);
      if (!state[id]) return { ...state, [id]: { [algo]: [widget] } };
      if (!state[id][algo])
        return { ...state, [id]: { ...state[id], [algo]: [widget] } };
      if (state[id][algo].includes(widget)) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          [algo]: addWidgetToList(state[id][algo], widget),
        },
      };

    case ADD_MULTIPLE_SUBS: {
      let newState = { ...state };
      for (let i = 0; i < action.payload.length; i += 1) {
        id = action.payload[i].id;
        algo = action.payload[i].algo;
        widget = action.payload[i].id;

        if (
          !newState[id] ||
          !newState[id][algo] ||
          newState[id][algo].length === 0
        ) {
          startSubscription(id, algo);
        }
        if (!newState[id]) {
          newState = { ...newState, [id]: { [algo]: [widget] } };
        }
        if (!newState[id][algo]) {
          newState = {
            ...newState,
            [id]: { ...newState[id], [algo]: [widget] },
          };
        }

        if (!newState[id][algo].includes(widget)) {
          newState = {
            ...newState,
            [id]: {
              ...newState[id],
              [algo]: addWidgetToList(newState[id][algo], widget),
            },
          };
        }
      }

      return newState;
    }
    case UPDATE_SUB_ALGO:
      return state;
    default:
      return state;
  }
}

const addWidgetToList = (list, widget) => [...list, widget];

const removeWidgetFromList = (list, widget) => list.filter((w) => w !== widget);

const stopSubscription = (id, algo) => {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'stop-instrument-subscription',
        data: { id, algo },
      },
    })
  );
};

const startSubscription = (id, algo) => {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'start-instrument-subscription',
        data: { id, algo },
      },
    })
  );
};
