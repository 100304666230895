import decimalPlaces from './decimalPlaces';

export default function splitPrice(p) {
  var priceArray = [];
  const price = p.toString();

  if (decimalPlaces(price) === 5 || decimalPlaces(price) === 3) {
    priceArray[0] = price.substr(0, price.length - 3);
    priceArray[1] = price.substr(price.length - 3, 2);
    priceArray[2] = price.substr(-1);
    return priceArray;
  }

  priceArray[0] = price.substr(0, price.length - 2);
  priceArray[1] = price.substr(price.length - 2);
  return priceArray;
}

export function splitPriceCOP(p) {
  var priceArray = [];
  const price = p.toString();
  const splitOnPeriod = price.split('.');

  if (splitOnPeriod.length === 2 && splitOnPeriod[0].length === 4) {
    // Patch for USD/COP
    priceArray[0] = splitOnPeriod[0].substr(0, splitOnPeriod[0].length - 2);
    priceArray[1] = splitOnPeriod[0].substr(-2);
    priceArray[2] = `.${splitOnPeriod[1]}`;

    return priceArray;
  }

  if (decimalPlaces(price) === 5 || decimalPlaces(price) === 3) {
    priceArray[0] = price.substr(0, price.length - 3);
    priceArray[1] = price.substr(price.length - 3, 2);
    priceArray[2] = price.substr(-1);
    return priceArray;
  }

  priceArray[0] = price.substr(0, price.length - 2);
  priceArray[1] = price.substr(price.length - 2);
  return priceArray;
}
