const creditInit = {
  doubleLine: false,
  credit_type: 'unlimited',
  credit_limit: 0,
  trading_power: {
    buy: 0,
    sell: 0,
  },
  instruments: {},
  current_usd_used_amount: 0,
};

type CreditObject = {
  doubleLine: boolean;
  instruments: { [key: string]: number };
  credit_limit: any;
  credit_type: string;
  [key: string]: any;
};

const getCreditCheckCurrent = (
  {
    doubleLine,
    instruments,
    credit_limit: creditLimit,
    credit_type: creditType,
    ...rest
  }: CreditObject,
  type: string
) => {
  if (!doubleLine) {
    return {
      ...creditLimit,
      instruments,
      credit_type: creditType,
    };
  }

  if (type) {
    const typeCurrent = ['fx', 'ndf'].includes(type) ? type : 'fx';
    const creditLimitCurrent = rest[`credit_limit_${typeCurrent}`];
    return { ...creditLimitCurrent, instruments, credit_type: creditType };
  }

  return creditInit;
};

export { creditInit, getCreditCheckCurrent };
