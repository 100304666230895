import { configureStore, Action } from '@reduxjs/toolkit';
import reducer from './reducers/index';
import makeUniqueComponentID from './utils/algorithms';
import { DISABLE_UI } from './actions/action_types';

window.identifier = makeUniqueComponentID();

type ExtendedAction = Action & {
  source: boolean;
};

type Next = (action: ExtendedAction) => void;

const getReduxStore = () => {
  // #region middleware
  const storageMiddleware = () => (next: Next) => (action: ExtendedAction) => {
    // If there is any more actions needed they should be added here
    if (!action.source) {
      if (action.type === DISABLE_UI) {
        const wrappedAction = { source: true, ...action };
        localStorage.setItem(window.identifier, JSON.stringify(wrappedAction));
      }
    }
    next(action);
  };

  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(storageMiddleware),
  });
};

export default getReduxStore;
