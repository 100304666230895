import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

// YYYYMMDD to YYYY/MM/DD
export function formatDateWithSlashes(date) {
  if (!date || date === '') {
    return '';
  }

  const dateString = date.toString();

  if (dateString.length !== 8) {
    return '';
  }

  return (
    `${dateString.slice(0, 4)}/` +
    `${dateString.slice(4, 6)}/` +
    `${dateString.slice(-2)}`
  );
}

export function formatDateWithoutSlashes(date) {
  if (!date) {
    return '';
  }

  const dateToProcess = typeof date === 'string' ? parseISO(date) : date;

  if (!isValid(dateToProcess)) {
    return '';
  }

  return format(dateToProcess, 'yyyyMMdd');
}

// Date Obj to YYYY/MM/DD hh:mm:ss(.ms)
export function formatDateObj(date, withoutMS) {
  if (!(date instanceof Date)) {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  return withoutMS
    ? `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
    : `${year}/${month}/${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function formatDateToTime(date, withoutMS) {
  if (!(date instanceof Date)) {
    return '';
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  return withoutMS
    ? `${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
