import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RequireAuth from './components/auth/RequireAuth';
import Loading from './components/common/loading';
import { Dashboard, NotFound } from './pages';

const SignIn = lazy(() => import('./pages/Login'));
const Desktop = lazy(() => import('./pages/desktop'));

const HOC = () => (
  <RequireAuth>
    <Dashboard />
  </RequireAuth>
);

const LazyComponent = (Component: React.FC) => () =>
  (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );

const routes = (
  <Router>
    <Switch>
      <Route path="/" exact component={LazyComponent(SignIn)} />
      <Route path="/desktop" component={LazyComponent(Desktop)} />
      <Route path="/dashboard" component={HOC} />
      <Route path="/widget" component={HOC} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default routes;
