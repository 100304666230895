import wl from '../configs/wl';

const getUrlLogo = ({ app, type }) => {
  let urlLogo = '';
  const arrayAppsPng = [
    'msri',
    'axiafx',
    'sberbankfx',
    'edfmanfx',
    'multivafx',
    'bcpfx',
    'cibanco',
    'bancolombia',
    'makor',
    'edgefxprime',
    'shinhan',
    'banorte',
  ];
  if (type === 'in') {
    urlLogo = `static/images/${wl[app]?.branding || app}-logo.${
      arrayAppsPng.includes(app) ? 'png' : 'svg'
    }`;
  } else {
    urlLogo = `/static/images/${wl[app]?.branding || app}-logo-by.${
      arrayAppsPng.includes(app) ? 'png' : 'svg'
    }`;
  }
  urlLogo += `?t=${new Date().getTime()}`;
  return urlLogo;
};

export default getUrlLogo;
