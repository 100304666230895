import React, { useState, useRef, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import presenter, { CtrlState } from './presenter';
import FormInputPlusLabel from '../../common/form/FormInputPlusLabel';

const ID_CTRL_USERNAME = 'username';
const ID_CTRL_PASSWORD = 'current-password';

const Form = () => {
  const presenting = presenter({
    ID_CTRL_USERNAME,
    ID_CTRL_PASSWORD,
  });
  const initialFormState = useRef<CtrlState>(presenting.initialFormState);
  const [ctrlState, setCtrlState] = useState<CtrlState>(
    initialFormState.current
  );

  const [error, setError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const history = useHistory();

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    setIsAuthenticating(true);

    presenting
      .handleSubmit({
        username: ctrlState[ID_CTRL_USERNAME].value,
        password: ctrlState[ID_CTRL_PASSWORD].value,
      })
      .then(() => {
        setIsAuthenticating(false);
        history.push({ pathname: '/dashboard' });
      })
      .catch(() => {
        setIsAuthenticating(false);
        setError(presenting.trans.label.authnError);
      });
  };

  const handleInputUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCtrlState(
      presenting.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  return (
    <form data-testid="components:auth:signin:form" onSubmit={submitHandler}>
      <div className="login-data-form" id="mextraderLogin">
        {error && (
          <span role="alert" className="error">
            {error}
          </span>
        )}
        <FormInputPlusLabel
          elementId={ID_CTRL_USERNAME}
          className={ctrlState[ID_CTRL_USERNAME].className}
          type="text"
          required={ctrlState[ID_CTRL_USERNAME].isRequired}
          value={ctrlState[ID_CTRL_USERNAME].value}
          changeHandler={handleInputUpdate}
          autoComplete="username"
          labelText={presenting.trans.label.username}
        />
        <FormInputPlusLabel
          elementId={ID_CTRL_PASSWORD}
          className={ctrlState[ID_CTRL_PASSWORD].className}
          type="password"
          required={ctrlState[ID_CTRL_PASSWORD].isRequired}
          value={ctrlState[ID_CTRL_PASSWORD].value}
          changeHandler={handleInputUpdate}
          autoComplete="current-password"
          labelText={presenting.trans.label.password}
        />
        {isAuthenticating ? (
          <div className="spinner-container">
            <div className="spinner-login" />
          </div>
        ) : (
          <button type="submit">{presenting.trans.btn.signIn}</button>
        )}
      </div>
    </form>
  );
};

export default Form;
