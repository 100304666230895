import { USER_DETAILS } from '../actions/action_types';

export default function userDetailsReducer(
  state = {
    userId: 0,
    organizationId: 0,
    username: '',
    organization: '',
    rateEngineEnabled: false,
    shadowAdmin: false,
    isViewer: false,
  },
  action
) {
  switch (action.type) {
    case USER_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
