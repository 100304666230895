import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification from './Notification';

const Notifications = ({
  notifications,
  enterTimeout,
  leaveTimeout,
  onRequestHide,
}) => {
  const handleRequestHide = useCallback(
    (id) => () => {
      if (onRequestHide) {
        onRequestHide(id);
      }
    },
    [onRequestHide]
  );

  return (
    <div className="notification-container">
      <TransitionGroup>
        {notifications.map((notification) => (
          <CSSTransition
            key={notification.id}
            classNames="notification"
            timeout={{ enter: enterTimeout, exit: leaveTimeout }}
          >
            <Notification
              type={notification.type}
              title={notification.title}
              message={notification.message}
              timeOut={notification.timeOut}
              onClick={notification.onClick}
              onRequestHide={handleRequestHide(notification.id)}
              closeButton={notification.closeButton}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

Notifications.defaultProps = {
  notifications: [],
  onRequestHide: () => {},
  enterTimeout: 400,
  leaveTimeout: 400,
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onRequestHide: PropTypes.func,
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
};

export default Notifications;
