import wl from '../configs/wl';

function checkPlatforms(platForms) {
  // platForms as array
  // easy handle for multi platform support
  return platForms.includes((window.APP_NAME || '').toLowerCase());
}

export function getHost() {
  // logic for setting correct APP_NAME and APP_TITLE
  // based on hostname
  const hostname = window?.location?.hostname?.split('.');

  // try to match first part of hostname. if it doesn't exist
  // try to match against second part
  const [first, second] = hostname;
  let wlByHostname = wl[first] ?? wl[second];

  if (!wlByHostname) {
    wlByHostname = wl.localhost;
  }

  return wlByHostname;
}

export default checkPlatforms;
