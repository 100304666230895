import styled from '@material-ui/core/styles/styled';

const StyledLogin = styled('div')({
  width: '100%',
  position: 'relative',
  '@media (min-height: 420px)': {
    height: '100vh',
  },

  '& .login-wrap': {
    maxWidth: '375px',
    margin: 'auto',
    padding: '20px',
    '@media (min-height: 420px)': {
      top: '50%',
      left: 0,
      right: 0,
      '-webkit-transform': 'translateY(-50%)',
      transform: 'translateY(-50%)',
      position: 'absolute',
    },
  },

  '& .login-form': {
    textAlign: 'center',
    borderRadius: '4px',
    padding: '0 16px',

    '& .login-form__head': {
      width: '100%',
      height: '110px',

      '& img': {
        display: 'inline-block',
        height: '100%',
      },
    },

    '& .login-data-form': {
      '& button': {
        width: '100%',
        textAlign: 'center',
        border: 0,
        height: '50px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontFamily: "'Gotham-Bold', Helvetica, Arial, sans-serif",
        transition: 'all 150ms ease-in-out',
        marginTop: '16px',
        marginBottom: '16px',
        fontSize: '16px',
        paddingTop: '6px',
        textTransform: 'uppercase',
      },

      '& .input--login': {
        fontFamily: "'Gotham-Book', Helvetica, Arial, sans-serif",
        width: '100%',
        boxShadow: 'inset 0 2px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '4px',
        border: '1px solid #8c8c8c',
        color: '#595959',
        height: '50px',
        fontSize: '18px',
        padding: '2px 15px 0 15px',
      },

      '& label': {
        textTransform: 'uppercase',
        fontFamily: "'Gotham-Bold', Helvetica, Arial, sans-serif",
        fontSize: '14px',
        display: 'block',
        textAlign: 'left',
        marginTop: '16px',
        marginBottom: '8px',
      },

      '& .error': {
        color: '#262626',
        padding: '16px',
        borderRadius: '4px',
        display: 'block',
        marginTop: '16px',
        fontSize: '16px',
      },
    },
  },

  '& p': {
    textAlign: 'center',
    fontSize: '14px',
    color: '#a8a6ab',
    fontFamily: "'Gotham-Book', Helvetica, Arial, sans-serif",
    fontWeight: 300,
    marginTop: '16px',
  },
});

export default StyledLogin;
